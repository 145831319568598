"use client";

import { useTranslations } from "next-intl";
import { useParams } from "next/navigation";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import Terms from "../components/shared/Terms";
import Button from "../components/ui/Button";
import Dialog from "../components/ui/Dialog";
import { ForestState, useForestStore } from "../hooks/useForestStore";
import { useAcceptTermsConditions } from "../hooks/useUserQuery";
import { useAuth } from "./AuthContext";

type ForestContextType = ForestState;

const ForestContext = createContext<ForestContextType | undefined>(undefined);

export const ForestProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { forest, forestCode, setForestCode, setForest } = useForestStore();
  const params = useParams<{
    code: string;
  }>();

  const { user } = useAuth();
  const t = useTranslations();
  const {
    mutateAsync: acceptTerms,
    isLoading,
    isError,
  } = useAcceptTermsConditions();

  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(
    !user?.hasReadTermsConditions,
  );

  useEffect(() => {
    if (
      (params.code && !forestCode) ||
      (params.code &&
        params.code.toLowerCase().startsWith("cft-") &&
        params.code !== forestCode)
    ) {
      setForestCode(params.code);
    }
  }, [forestCode, params.code, setForestCode]);

  const handleClickAccept = () => {
    acceptTerms(true);
    setIsTermsDialogOpen(false);
  };

  if (isError) {
    console.error("Error accepting terms and conditions");
  }

  return (
    <ForestContext.Provider
      value={{
        forest,
        forestCode,
        setForestCode,
        setForest,
      }}
    >
      {user && !user.hasReadTermsConditions && (
        <Dialog
          title={t("page.footer.terms")}
          open={isTermsDialogOpen}
          fullSize
          noClose
        >
          <Terms />
          <div className="max-w-3xl mx-auto p-5 flex justify-center mb-4">
            <Button
              colorScheme="black"
              variant="primary"
              onClick={() => {
                handleClickAccept();
              }}
            >
              {isLoading
                ? t("forest_selection.loading")
                : t("forest_selection.accept_terms_submit")}
            </Button>
          </div>
        </Dialog>
      )}
      {children}
    </ForestContext.Provider>
  );
};

export const useForest = () => {
  const context = useContext(ForestContext);
  if (!context) {
    throw new Error("useForest must be used within a ForestProvider");
  }
  return context;
};
